import React, {useState} from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { useStaticQuery, graphql } from "gatsby";
import IndustryCard from "../components/industries/industry_card"
import IndustryModal from "../components/industries/industry_modal"
import "../components/industries/industries.css"

const IndustriesPage = (props) => {
  const data = useStaticQuery(graphql`
    query {
      strapiIndustryPageFrench {
        text
        title
      }
      allStrapiIndustry {
        nodes {
          cardText
          cardTextFrench
          description
          descriptionFrench
          id
          strapiId
          modalSubtitle
          modalSubtitleFrench
          name
          nameFrench
          mentors {
            name
            id
            profilePic {
              localFile {
                publicURL
              }
            }
            company
          }
          modalImage {
            localFile {
              publicURL
            }
          }
          cardImage {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  `)
  const pageInfo = data.strapiIndustryPageFrench
  const industries = data.allStrapiIndustry.nodes
  const [modalActive, setModalActive] = useState(false)
  const [currentIndustry, setCurrentIndustry] = useState(industries[0])
  const clearModalActive = ()=>{
    setModalActive(false)
  }
  const handleIndustryClick = (industry)=>{
    setCurrentIndustry(industry)
    setModalActive(true)
  }
  return (
  <Layout language="french" path={props.path}>
    <SEO language="french" title="Industries" />
    <IndustryModal
      clearModalActive={clearModalActive} 
      industry={currentIndustry} 
      active={modalActive}
      language="french"
    />
    <div className="mentors-top-section">
      <h1 className="page-title center">{pageInfo.title}</h1>
      <p>{pageInfo.text}</p>
    </div>
    <div className="industry-cards-container">
      {industries.map((i)=>{
        return(
          <IndustryCard language="french" key={i.id} handleIndustryClick={handleIndustryClick} industry={i} />
        )
      })}
    </div>
  </Layout>
)}

export default IndustriesPage